<template>
  <CFooter>
    <div>
      <span class="ms-1">&copy; Copyright {{ new Date().getFullYear() }} Mailfurnace LLC.</span>
    </div>
    <!-- <div class="ms-auto">
      <span class="me-1" target="_blank">Powered by</span>
      <a href="https://greenknight.ca/">CoreUI for Vue</a>
    </div> -->
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
